import { type AsyncTaskStatusOutput } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';

const coreEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAsyncTaskStatus: build.query<AsyncTaskStatusOutput, string>({
      query: (taskId) => `/task-status/${taskId}/`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAsyncTaskStatusQuery } = coreEndpoints;
