import {
  type OrganizationMemberCreateInput,
  type OrganizationMemberCreateOutput,
  type OrganizationMemberDetailOutput,
  type OrganizationMemberListOutputItem,
  type OrganizationMemberUpdateInput,
  type OrganizationMemberUpdateOutput,
  type OrganizationOwnerMemberUpdateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsWithTenantId,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'organization-members';

const organizationMemberEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationMemberList: build.query<OrganizationMemberListOutputItem[], { tid: string }>({
      query: ({ tid }) => `/tid/${tid}/${RESOURCE}/`,
      providesTags: [{ type: TagType.OrganizationMember, id: 'LIST' }],
    }),

    getOrganizationMember: build.query<OrganizationMemberDetailOutput, DetailParamsWithTenantId>({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: (result) => [{ type: TagType.OrganizationMember, id: result?.uuid }],
    }),

    createOrganizationMember: build.mutation<
      OrganizationMemberCreateOutput,
      CreateParamsWithTenantId<OrganizationMemberCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagType.OrganizationMember],
    }),

    deleteOrganizationMember: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: TagType.OrganizationMember, id: 'LIST' },
        { type: TagType.OrganizationMember, id: args.id },
      ],
    }),

    updateOrganizationMember: build.mutation<
      OrganizationMemberUpdateOutput,
      UpdateParamsWithTenantId<OrganizationMemberUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: TagType.OrganizationMember, id: 'LIST' },
        { type: TagType.OrganizationMember, id: args.id },
      ],
    }),

    updateOwner: build.mutation<
      OrganizationOwnerMemberUpdateOutput,
      UpdateParamsWithTenantId<OrganizationMemberUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/owner-update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: TagType.OrganizationMember, id: 'LIST' },
        { type: TagType.OrganizationMember, id: args.id },
        { type: TagType.UserProfile },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrganizationMemberListQuery,
  useGetOrganizationMemberQuery,
  useCreateOrganizationMemberMutation,
  useDeleteOrganizationMemberMutation,
  useUpdateOrganizationMemberMutation,
  useUpdateOwnerMutation,
} = organizationMemberEndpoints;
