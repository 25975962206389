import {
  type AssessmentControlPointCommentOutputItem,
  type AssessmentControlPointDetailOutput,
  type AssessmentControlPointsGetCategoryEvidenceTypeControlPointsListParams,
  type AssessmentControlPointShortcomingOutputItem,
  type AssessmentControlPointsListParams,
  type AssessmentControlPointUpdateInput,
  type AssessmentControlPointUpdateOutput,
  type PaginatedAssessmentCategoryEvidenceTypeControlPointListOutput,
  type PaginatedAssessmentControlPointListOutput,
  type PaginatedV2AssessmentControlPointListOutput,
  type V2AssessmentControlPointDetailOutput,
  type V2AssessmentControlPointsListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type DetailParamsWithTenantId,
  injectQueryParams,
  transformObject,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-control-points';

type TransformedAssessmentControlPointDetailOutput = Omit<
  AssessmentControlPointDetailOutput,
  'comments' | 'shortcomings'
> & {
  comments: TransformedAssessmentControlPointCommentOutputItem[];
  shortcomings: TransformedAssessmentControlPointNonConformityOutputItem[];
};

type TransformedAssessmentControlPointDetailOutputV2 = Omit<
  V2AssessmentControlPointDetailOutput,
  'comments' | 'shortcomings'
> & {
  comments: TransformedAssessmentControlPointCommentOutputItem[];
  shortcomings: TransformedAssessmentControlPointNonConformityOutputItem[];
};

export type TransformedAssessmentControlPointCommentOutputItem = Omit<
  AssessmentControlPointCommentOutputItem,
  'created_at' | 'modified_at'
> & {
  created_at: Date;
  modified_at: Date;
};

export type TransformedAssessmentControlPointNonConformityOutputItem = Omit<
  AssessmentControlPointShortcomingOutputItem,
  'created_at' | 'modified_at' | 'reported_date'
> & {
  created_at: Date;
  modified_at: Date;
  reported_date: Date;
};

const responseTransformations = {
  created_at: (value: string): Date => new Date(value),
  modified_at: (value: string): Date => new Date(value),
  reported_date: (value: string): Date => new Date(value),
};

const assessmentControlPointEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentCategoryEvidenceTypeControlPointList: build.query<
      PaginatedAssessmentCategoryEvidenceTypeControlPointListOutput,
      AssessmentControlPointsGetCategoryEvidenceTypeControlPointsListParams
    >({
      query: ({ tid, ...restParams }) =>
        injectQueryParams(
          `/tid/${tid}/${RESOURCE}/get-category-evidence-type-control-points/`,
          restParams,
          true,
        ),
      providesTags: [TagType.AssessmentControlPointList, TagType.UserProfile],
    }),

    getAssessmentControlPointList: build.query<
      PaginatedAssessmentControlPointListOutput,
      AssessmentControlPointsListParams
    >({
      query: ({ tid, ...restParams }) =>
        injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams, true),
      providesTags: (_response, _error, params) => [
        TagType.AssessmentControlPointList,
        TagType.UserProfile,
        ...(params.has_evidence !== null ? [TagType.AssessmentControlPointEvidenceFilter] : []),
        ...(params.answer_type !== null ? [TagType.AssessmentControlPointAnswerTypeFilter] : []),
      ],
    }),

    getAssessmentControlPointListV2: build.query<
      PaginatedV2AssessmentControlPointListOutput,
      V2AssessmentControlPointsListParams
    >({
      query: ({ tid, ...restParams }) =>
        injectQueryParams(`/tid/${tid}/v2/${RESOURCE}/`, restParams, true),
      providesTags: (_response, _error, params) => [
        TagType.AssessmentControlPointList,
        TagType.UserProfile,
        ...(params.has_evidence !== null ? [TagType.AssessmentControlPointEvidenceFilter] : []),
        ...(params.answer_type !== null ? [TagType.AssessmentControlPointAnswerTypeFilter] : []),
      ],
    }),

    getAssessmentControlPoint: build.query<
      TransformedAssessmentControlPointDetailOutput,
      DetailParamsWithTenantId & { signal: AbortSignal }
    >({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: (response) => [
        { type: TagType.AssessmentControlPoint, id: response?.control_point_id },
        TagType.ResponseAnswers,
      ],
      transformResponse: (
        data: AssessmentControlPointDetailOutput,
      ): TransformedAssessmentControlPointDetailOutput => ({
        ...data,
        comments: data.comments?.map((item) => transformObject(item, responseTransformations)),
        shortcomings: data.shortcomings?.map((item) =>
          transformObject(item, responseTransformations),
        ),
      }),
    }),

    getAssessmentControlPointV2: build.query<
      TransformedAssessmentControlPointDetailOutputV2,
      DetailParamsWithTenantId & { signal: AbortSignal }
    >({
      query: ({ tid, id }) => `/tid/${tid}/v2/${RESOURCE}/${id}/`,
      providesTags: (response) => [
        { type: TagType.AssessmentControlPoint, id: response?.control_point_id },
        TagType.Responses,
        TagType.ResponseAnswers,
      ],
      transformResponse: (
        data: V2AssessmentControlPointDetailOutput,
      ): TransformedAssessmentControlPointDetailOutputV2 => ({
        ...data,
        comments: data.comments?.map((item) => transformObject(item, responseTransformations)),
        shortcomings: data.shortcomings?.map((item) =>
          transformObject(item, responseTransformations),
        ),
      }),
    }),

    updateAssessmentControlPoint: build.mutation<
      AssessmentControlPointUpdateOutput,
      UpdateParamsWithTenantId<AssessmentControlPointUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [TagType.AssessmentControlPointList],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAssessmentCategoryEvidenceTypeControlPointListQuery,
  useGetAssessmentControlPointListQuery,
  useGetAssessmentControlPointListV2Query,
  useLazyGetAssessmentControlPointQuery,
  useLazyGetAssessmentControlPointV2Query,
  useUpdateAssessmentControlPointMutation,
} = assessmentControlPointEndpoints;
