import {
  type ProductDetailOutput,
  type ProductListOutputItem,
  type ProductsListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { type DetailParamsWithTenantId, injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'products';

const productEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProductList: build.query<ProductListOutputItem[], ProductsListParams>({
      query: ({ tid, ...params }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, params),
      providesTags: [TagType.Product, TagType.UserProfile],
    }),

    getProduct: build.query<ProductDetailOutput, DetailParamsWithTenantId>({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: [TagType.Product, TagType.UserProfile],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProductQuery, useGetProductListQuery } = productEndpoints;
