import {
  type ComplianceReportOutput,
  type CultivationBonusPointsPerThemeOutputItem,
  type MalusComplensationPerCultivationOutputItem,
  type NonCompliantControlPointListOutputItem,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { type DetailParamsWithTenantId } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-reports';

interface DetailParamsWithStandardId extends DetailParamsWithTenantId {
  standardId: string;
}

const assessmentReportEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    reportPageDataReadyApi: build.mutation<void, DetailParamsWithStandardId>({
      query: ({ id, standardId, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/report-page-data-ready/${standardId}/`,
        method: 'PUT',
        body: {},
      }),
    }),

    getAssessmentComplianceReportList: build.query<
      ComplianceReportOutput,
      DetailParamsWithStandardId
    >({
      query: ({ id, standardId, tid }) =>
        `/tid/${tid}/${RESOURCE}/${id}/get-compliance-report/${standardId}/`,
      providesTags: [
        TagType.AssessmentReports,
        TagType.AssessmentControlPointAnswer,
        TagType.ResponseAnswers,
        TagType.AssessmentControlPointInputs,
        TagType.AssessmentControlPointNonConformities,
        TagType.NonConformityAndCorrectiveAction,
      ],
    }),

    getAssessmentBonusPointsList: build.query<
      CultivationBonusPointsPerThemeOutputItem[],
      DetailParamsWithStandardId
    >({
      query: ({ id, standardId, tid }) =>
        `/tid/${tid}/${RESOURCE}/${id}/get-cultivation-bonus-points-per-theme/${standardId}/`,
      providesTags: [
        TagType.AssessmentReports,
        TagType.AssessmentControlPointAnswer,
        TagType.ResponseAnswers,
        TagType.AssessmentControlPointInputs,
      ],
    }),

    getAssessmentMalusPointsList: build.query<
      MalusComplensationPerCultivationOutputItem[],
      DetailParamsWithStandardId
    >({
      query: ({ id, standardId, tid }) =>
        `/tid/${tid}/${RESOURCE}/${id}/get-malus-compensation-per-cultivation/${standardId}/`,
      providesTags: [
        TagType.AssessmentReports,
        TagType.AssessmentControlPointAnswer,
        TagType.ResponseAnswers,
        TagType.AssessmentControlPointInputs,
      ],
    }),

    getAssessmentNonCompliantControlPointList: build.query<
      NonCompliantControlPointListOutputItem[],
      DetailParamsWithStandardId
    >({
      query: ({ id, standardId, tid }) =>
        `/tid/${tid}/${RESOURCE}/${id}/get-non-compliant-control-points/${standardId}/`,
      providesTags: [
        TagType.AssessmentReports,
        TagType.AssessmentControlPointAnswer,
        TagType.ResponseAnswers,
        TagType.AssessmentControlPointList,
        TagType.AssessmentControlPoint,
        TagType.AssessmentControlPointInputs,
        TagType.AssessmentControlPointNonConformities,
        TagType.NonConformityAndCorrectiveAction,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReportPageDataReadyApiMutation,
  useGetAssessmentComplianceReportListQuery,
  useGetAssessmentBonusPointsListQuery,
  useGetAssessmentMalusPointsListQuery,
  useGetAssessmentNonCompliantControlPointListQuery,
} = assessmentReportEndpoints;
