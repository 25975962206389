import { useGridLogger } from "./useGridLogger.js";
import { useGridApiOptionHandler } from "./useGridApiEventHandler.js";
export const useGridNativeEventListener = (apiRef, ref, eventName, handler, options) => {
  const logger = useGridLogger(apiRef, 'useNativeEventListener');
  useGridApiOptionHandler(apiRef, 'rootMount', () => {
    const targetElement = typeof ref === 'function' ? ref() : ref.current;
    if (!targetElement || !eventName || !handler) {
      return undefined;
    }
    logger.debug(`Binding native ${eventName} event`);
    targetElement.addEventListener(eventName, handler, options);
    return () => {
      logger.debug(`Clearing native ${eventName} event`);
      targetElement.removeEventListener(eventName, handler, options);
    };
  });
};