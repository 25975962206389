import { GRID_ID_AUTOGENERATED } from "../features/rows/gridRowsUtils.js";

/**
 * Get the row id for a given row
 * @param state - The grid state
 * @param {GridRowModel} row - The row to get the id for
 * @returns {GridRowId} The row id
 */
export const gridRowIdSelector = (state, row) => {
  if (GRID_ID_AUTOGENERATED in row) {
    return row[GRID_ID_AUTOGENERATED];
  }
  return state.props.getRowId ? state.props.getRowId(row) : row.id;
};