import {
  type HelpDocumentDetailOutput,
  type HelpDocumentListOutputItem,
  type HelpDocumentsListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams, transformObject } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'help-documents';

export type TransformedHelpDocumentListOutputItem = Omit<
  HelpDocumentListOutputItem,
  'expiry_date' | 'modified_at' | 'created_at'
> & {
  created_at: Date;
  modified_at: Date;
};

type TransformedHelpDocumentDetailOutput = Omit<
  HelpDocumentDetailOutput,
  'created_at' | 'modified_at'
> & {
  created_at: Date;
  modified_at: Date;
};

const responseTransformations = {
  created_at: (value: string): Date => new Date(value),
  modified_at: (value: string): Date => new Date(value),
};

const helpDocumentEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getHelpDocumentList: build.query<
      TransformedHelpDocumentListOutputItem[],
      HelpDocumentsListParams
    >({
      query: ({ tid, ...params }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, params),
      providesTags: () => [{ type: TagType.HelpDocument, id: 'LIST' }],
      transformResponse: (
        data: HelpDocumentListOutputItem[],
      ): TransformedHelpDocumentListOutputItem[] =>
        data.map((item) => transformObject(item, responseTransformations)),
    }),

    getHelpDocument: build.query<TransformedHelpDocumentDetailOutput, string>({
      query: (id) => `/${RESOURCE}/${id}/`,
      providesTags: [TagType.HelpDocument],
      transformResponse: (data: HelpDocumentDetailOutput) =>
        transformObject(data, responseTransformations),
    }),
  }),
  overrideExisting: false,
});

export const { useGetHelpDocumentListQuery, useGetHelpDocumentQuery } = helpDocumentEndpoints;
