import { RolesEnum } from 'src/__generated__/InternalApiTypes';

import { useCurrentUserRoles } from './useCurrentUserRoles';

export const useRoleCheck = () => {
  const currentUserRoles = useCurrentUserRoles();

  const isAuditor = currentUserRoles.includes(RolesEnum.Auditor);
  const isCBManager = currentUserRoles.includes(RolesEnum.CertificationBodyManager);
  const isFarmManager = currentUserRoles.includes(RolesEnum.FarmManager);
  const isFarmEmployee = currentUserRoles.includes(RolesEnum.FarmEmployee);
  const isFarmAdviser = currentUserRoles.includes(RolesEnum.FarmAdviser);
  const isFarm = isFarmManager || isFarmEmployee || isFarmAdviser;
  const isFarmSecondaryRole = isFarmEmployee || isFarmAdviser; // Not a farm manager
  const isCB = isAuditor || isCBManager;
  const isStandardSetter = currentUserRoles.includes(RolesEnum.StandardSetter);
  const isOwner = currentUserRoles.includes(RolesEnum.Owner);

  return {
    isAuditor,
    isCB,
    isCBManager,
    isFarm,
    isFarmSecondaryRole,
    isFarmManager,
    isFarmAdviser,
    isFarmEmployee,
    isStandardSetter,
    isOwner,
  };
};
