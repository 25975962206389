import {
  type ResponseAnswerAdditionDetailUpdateInput,
  type ResponseAnswerAdditionDetailUpdateOutput,
  type ResponseAnswerBulkUpdateInput,
  type ResponseAnswerBulkUpdateOutputItem,
  type ResponseAnswerCreateInput,
  type ResponseAnswerCreateOutputItem,
  type ResponseAnswerListOutput,
  type ResponseAnswersListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type BulkUpdateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  injectQueryParams,
  type UpdateParamsOnlyTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'response-answers';

const responseAnswerEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getResponseAnswerList: build.query<ResponseAnswerListOutput[], ResponseAnswersListParams>({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: [
        TagType.ResponseAnswers,
        TagType.UserProfile,
        TagType.AssessmentControlPointEvidence,
      ],
    }),

    updateResponseAnswer: build.mutation<
      ResponseAnswerCreateOutputItem,
      UpdateParamsOnlyTenantId<ResponseAnswerCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/update/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result: ResponseAnswerCreateOutputItem) => [
        TagType.ResponseAnswers,
        TagType.AssessmentProgress,
        TagType.AssessmentControlPointList,
        TagType.AssessmentControlPointAnswerTypeFilter,
        TagType.IsAssessmentChecklistCompleted,
        ...(result.has_associated_visibility_triggers ? [TagType.AssessmentControlPoint] : []),
      ],
    }),

    updateAdditionalDetail: build.mutation<
      ResponseAnswerAdditionDetailUpdateOutput,
      UpdateParamsOnlyTenantId<ResponseAnswerAdditionDetailUpdateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/additional-detail-update/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [
        TagType.ResponseAnswers,
        TagType.AssessmentControlPointList,
        TagType.AssessmentControlPointAnswerTypeFilter,
        TagType.IsAssessmentChecklistCompleted,
      ],
    }),

    deleteResponseAnswer: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        TagType.ResponseAnswers,
        TagType.AssessmentControlPointAnswerTypeFilter,
        TagType.IsAssessmentChecklistCompleted,
        TagType.AssessmentProgress,
      ],
    }),

    bulkUpdateResponseAnswer: build.mutation<
      ResponseAnswerBulkUpdateOutputItem[],
      BulkUpdateParamsWithTenantId<ResponseAnswerBulkUpdateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/bulk-update/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (results: ResponseAnswerBulkUpdateOutputItem[]) => [
        TagType.ResponseAnswers,
        TagType.AssessmentProgress,
        TagType.AssessmentControlPointAnswerTypeFilter,
        TagType.IsAssessmentChecklistCompleted,
        ...(results.some((result) => result.has_associated_visibility_triggers)
          ? [TagType.AssessmentControlPointList, TagType.AssessmentControlPoint]
          : []),
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useBulkUpdateResponseAnswerMutation,
  useDeleteResponseAnswerMutation,
  useGetResponseAnswerListQuery,
  useUpdateAdditionalDetailMutation,
  useUpdateResponseAnswerMutation,
} = responseAnswerEndpoints;
