import { SearchRounded } from '@mui/icons-material';
import { Autocomplete, Box, Divider, InputAdornment, Skeleton, TextField } from '@mui/material';
import React, {
  createElement,
  Fragment,
  type HTMLAttributes,
  type SyntheticEvent,
  useState,
} from 'react';
import { appColors } from 'src/theme';
import { type LabelValuePair } from 'src/types/LabelValuePair';

import { type FormikTextFieldProps } from '../FormikTextField';

interface FormikAutocompleteFieldProps extends FormikTextFieldProps {
  isLoading: boolean;
  options: LabelValuePair[];
  dividerIndex?: number;
  onDelete?: () => void;
}

/**
 * This component mates a Formik instance (created via the useFormik hook) with a MUI (autocomplete)
 * TextField.
 */
export const FormikAutocompleteField = ({
  dividerIndex,
  formik,
  id,
  label,
  options,
  onDelete,
  disabled = false,
  isLoading = false,
  placeholder = '',
  ...restProps
}: FormikAutocompleteFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const getOptionLabel = (option: LabelValuePair) => {
    if (option?.label) {
      return option?.label;
    }
    return options.find((opt) => opt.value === option?.value)?.label ?? '';
  };

  const renderOption = (
    itemProps: HTMLAttributes<HTMLLIElement> & { key: string },
    option: LabelValuePair,
    index: number,
  ) => {
    const { key, ...otherProps } = itemProps;
    return (
      <Fragment key={key}>
        {createElement(
          'li',
          {
            ...otherProps,
            key,
          },
          getOptionLabel(option),
        )}
        {dividerIndex === index && (
          <Divider sx={{ borderBottom: 2, borderBottomColor: 'divider' }} />
        )}
      </Fragment>
    );
  };

  return isLoading ? (
    <Box
      sx={{
        border: 1,
        borderColor: appColors.border,
        borderRadius: '4px',
        px: '14px',
        py: '15px',
        width: '100%',
      }}
    >
      <Skeleton
        animation="wave"
        variant="text"
        width="33%"
      />
    </Box>
  ) : (
    <Autocomplete
      disabled={isLoading || disabled}
      fullWidth
      getOptionKey={(option) => option.value.toString()}
      getOptionLabel={(option) => {
        if (option?.label) {
          return option?.label;
        }
        return options.find((opt) => opt.value === option)?.label ?? '';
      }}
      id={id}
      isOptionEqualToValue={(option, selectedItem) =>
        option?.value?.toString() === selectedItem?.toString()
      }
      onChange={(_: SyntheticEvent, newValue: LabelValuePair | null) => {
        formik?.setFieldValue(id, newValue?.value ?? '');
        if (!newValue?.value) {
          onDelete?.();
        }
      }}
      options={options ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          error={formik?.touched[id] && Boolean(formik.errors[id])}
          helperText={formik?.touched[id] && (formik.errors[id] as string)}
          id={id}
          label={label}
          name={id}
          onBlur={(evt) => {
            setIsFocused(false);
            formik?.handleBlur(evt);
          }}
          onFocus={() => setIsFocused(true)}
          placeholder={typeof label === 'string' ? placeholder || label : placeholder}
          slotProps={{
            input: {
              ...params.InputProps,
              startAdornment: isFocused && (
                <InputAdornment
                  position="start"
                  sx={{ mr: 0 }}
                >
                  <SearchRounded sx={{ opacity: 0.5 }} />
                </InputAdornment>
              ),
            },
          }}
          {...restProps}
        />
      )}
      renderOption={
        dividerIndex ? (props, option, { index }) => renderOption(props, option, index) : undefined
      }
      value={formik?.values[id] ? formik.values[id] : null}
    />
  );
};
