import { CountryEnum, TopCountryEnum } from 'src/__generated__/InternalApiTypes';
import { useCurrentLanguage, useLocalizedCountryName } from 'src/hooks';

import { FormikAutocompleteField } from '../FormikAutocompleteField';
import { type FormikTextFieldProps } from '../FormikTextField';

type FormikCountryFieldProps = FormikTextFieldProps;

/**
 * This component pre-fills a FormikSelectField with country options translated based on the user's
 * language (via the store).
 */
export const FormikCountryField = ({
  formik,
  id,
  isLoading = false,
  ...restProps
}: FormikCountryFieldProps) => {
  const language = useCurrentLanguage();
  const localizedCountryName = useLocalizedCountryName();
  const getCountryOptions = <T extends Record<string, string>>(enumObj: T) =>
    Object.keys(enumObj).map((code) => ({
      value: enumObj[code as keyof T],
      label: localizedCountryName.of(code) ?? '',
    }));

  const topOptions = getCountryOptions(TopCountryEnum);
  const allOptions = getCountryOptions(CountryEnum);
  const combinedOptions = [
    ...topOptions,
    ...allOptions.filter(
      (opt) => !topOptions.some((topOpt) => topOpt.value === (opt.value as string)),
    ),
  ];

  return (
    <FormikAutocompleteField
      dividerIndex={topOptions.length - 1}
      formik={formik}
      id={id}
      isLoading={isLoading || !language}
      options={combinedOptions}
      {...restProps}
    />
  );
};
